import React from 'react';

function YourMeter(){
  return(
    <>
      <section className='section-3'>
        <div className='container'>
          <h3 className='text-center pt-5 pb-3 section-header'>Your Meter, Your Way</h3>
          {/* row 1 */}
          <div className='row'>
            <div className='col-md-6'>
              <img className='rounded imgResponsive' src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FHome%2Fepi-flow-meter-customizer.png?alt=media&token=b742df14-3013-4bae-bd60-4d1b3ec5047b" alt="Man on Laptop using Flow Meter Customizer on EPI Website"/>
            </div>
            <div className='col-md-6 d-flex align-items-center'>
              <div className='responsiveMarginB25'>
                <h2 className='s3-h2'>Introducing the New Meter Configurator</h2>
                <p className='s3-p'>
                    With the new EPI Meter Customizer, you can design the perfect flow meter for
                    your application. Customize almost everything, including approvals, orientation, display options,
                    communication options, and much more. When you’re finished, we’ll send you a customized quote in seconds!
                </p>
                <a href="/allproducts" className='btn btn-primary'>Start Customizing</a>
              </div>
            </div>
          </div>
          {/* row 1 */}
          {/* row 2 start */}
          <div className='row s3-row2'>
            <div className='col-md-6 d-flex align-items-center'>
              <div className='responsiveMarginB25'>
                <h2 className='s3-h2'>Handcrafted Meters Designed Specifically for You</h2>
                <p className='s3-p'>
                  Every meter shipped by EPI is manufactured in the United States by a team of experienced specialists and goes through an extensive testing and calibration procedure before being signed off. Every effort is made to guarantee that all items are dispatched swiftly, precisely, and safely to your door.
                </p>
              </div>
            </div>
            <div className='col-md-6'>
              <img className='rounded imgResponsive' src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Fhomepage%2FFeature%2Fhigh-standard-quality-control-flow-meters.png?alt=media&token=a0dc5bce-9914-44ab-b2d3-830492987a33" alt="Flow meters ready to be inspected" />
            </div>
          </div>

          {/* row 2 end */}
          {/* row 3 start */}
          <div className='row pb-5'>
            <div className='col-md-6'>
              <img className='rounded imgResponsive' src="https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Fhomepage%2FFeature%2Fproven-accuracy-and-reliability.png?alt=media&token=48c5da77-fe90-4be6-aa95-e3e94d5847df" alt="ValuMass flow meter closeup" />
            </div>
            <div className='col-md-6 d-flex align-items-center'>
              <div className='responsiveMarginB25'>
                <h2 className='s3-h2'>Demonstrating Precision and Durability</h2>
                <p className='s3-p'>
                  To imitate the circumstances of your process line, our flow calibration specialists use a range of gases. Every EPI flow meter includes a live zero validation that allows you to evaluate the meter calibration within a 2 percent -5 percent tolerance and ten years of technical assistance and remote help from our technical staff to guarantee your meter continues to exceed your expectations.
                </p>
              </div>
            </div>
          </div>
          {/* row 3 end */}
        </div>
      </section>
    </>
  );
}

export default YourMeter;